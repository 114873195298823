// src/components/screens/ClubList.tsx
import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, Container, Typography, Box, Button, Grid, Link, Stepper, Step, StepLabel, StepIconProps } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Logo from '../../../assets/images/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { footerLinks } from '../Shared/footerLinks';
import { theme, style, config, StyledRadio } from '../../../config/settings.jsx';
import { steps } from '../../../config/steps';
import { api } from '../../../config/apis.jsx';

const LaunchConsent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [consentLink, setConsentLink] = useState('');
  const cdrid = localStorage.getItem('cdrid');
  const apikey = 'F3tDuot6*otiwCp$aRW9ahcPKHkMUwxUUE&tW4#vg84%YV7Ud56s@dVhCzoZrkBpRjwF!96Pp7Z*PJZFZuSC5ZesHScYNtQxij%A^Ta$SNA@QFd*!HZ2k4h@JH3cZYtf';

  const fetchConsent = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(api.consent,
      {cdrid: cdrid},
      { headers: { 'Content-Type': 'application/json',
          apikey: config.apikey,
            tenant: config.tenant
       } }); // Replace with your actual API endpoint
      const consentLink = response.data.consentLink;
      window.location.href = consentLink // Adjust based on your API response structure
    } catch (error) {
      console.error('Error creating consent Link:', error);
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">
 
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} >
      <Grid container justifyContent="center" mb={2}>
  
      {/*}
      <Grid item xs={12} justifyContent="center">
      <Box display="flex" justifyContent="center" mb={4}>
      <img src={Logo} alt="Logo"  style={{ width: '70%', height: 'auto'}} />
      </Box></Grid> */}
           <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%'}} >
        <Stepper activeStep={1} alternativeLabel>
        {steps.map((label) => (
          <Step key={label} >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
        </Box>
      
        <Typography variant="h4" component="h2" align="center" mb={2} sx={{fontWeight: 'bold'}} gutterBottom>
        Welcome
      </Typography>
      
      <Box display="flex" flexDirection="column" alignItems="left" mb={2} style={{ width: '100%'}}>
      <Typography variant="body2" component="h2" align="left" mb={2} gutterBottom>
        We will guide you through the last few steps to finish setting up your accounts!
      </Typography>
      <Typography variant="body2" component="h2" align="left"  gutterBottom>
      You'll be asked to connect to your bank using your internet banking credentials, select a round-up amount and the accounts you'd like to round up transactions from, then finally select a single funding account that we'll debit your total from every Monday, Wednesday and Friday.
      </Typography>
   
      </Box>
       
      </Grid>
      
   
          <Button
            onClick={fetchConsent}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: "100%"  }}
            disabled={isLoading}

                  
          >
            {isLoading ? 'Loading...' : 'Continue'}
         
          </Button>


          { footerLinks }
        </Box>
    </Container> </ThemeProvider>
  );
};

export default LaunchConsent;

export {};