import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { TextField, Container, Typography, Box, Button, Grid, Link, FormControlLabel, FormControl, FormLabel, RadioGroup, Switch, Collapse, CssBaseline, Divider, Toolbar, AppBar, Modal, ThemeProvider } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { theme, style, config, StyledRadio } from '../../../config/settings.jsx';
import { api } from '../../../config/apis.jsx';
import Logo from '../../../assets/images/logo_b.svg';
import DrawerMenu from '../Shared/DrawerMenu';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useFormik } from 'formik';
import * as yup from 'yup';

// Memoize validation schema to prevent re-creating it on every render
const validationSchema = yup.object({
  amount: yup.number().required('Amount is required').max(250, 'Amount must be $250 or less'),
});

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDonation, setIsLoadingDonation] = useState(false);
  const [roundUpsOn, setRoundUpsOn] = useState(true);
  const [weeklyLimitOn, setWeeklyLimitOn] = useState(false);
  const [weeklyLimitValue, setWeeklyLimitValue] = useState(0);
  const [roundUpValue, setRoundUpValue] = useState(100);
  const [supportedClub, setSupportedClub] = useState('');
  const [totalDebitAmount, setTotalDebitAmount] = useState(0);
  const [sortedDebitHistory, setSortedDebitHistory] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPauseThem, setOpenPauseThem] = useState(false);
  const [isPausedLoading, setIsPausedLoading] = useState(false);
  const [isDonated, setIsDonated] = useState(false);
  const navigate = useNavigate();
  const userId = useMemo(() => localStorage.getItem('userId'), []);

  // Avoid state updates on each render by using callbacks
  const getUserRecord = useCallback(async () => {
    if (!userId) {
      navigate('/');
      return;
    }
    try {
      const response = await axios.post(api.getUser, { userId }, {
        headers: { 'Content-Type': 'application/json', apikey: config.apikey, tenant: config.tenant }
      });
      const userRecord = response.data.userRecord.document;
      localStorage.setItem('cdrid', userRecord._id);
      setSupportedClub(userRecord.supportingClubId);
      setRoundUpsOn(!userRecord.isUserPausedRoundUp);
      setWeeklyLimitOn(userRecord.weeklyMaximumLimit > '0');
      setWeeklyLimitValue(userRecord.weeklyMaximumLimit);
      setRoundUpValue(userRecord.roundupAmountCents);
    } catch (error) {
      console.error('Error fetching user record:', error);
    }
  }, [userId, navigate]);

  useEffect(() => {
    getUserRecord();
  }, [getUserRecord]);

  // Grouping API calls into a single useEffect for better performance
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clubsResponse, debitsResponse] = await Promise.all([
          axios.get(api.retrieveClubs, {
            headers: { 'Content-Type': 'application/json', apikey: config.apikey, tenant: config.tenant }
          }),
          axios.post(api.retrieveTotalDebits, { cdrid: localStorage.getItem('cdrid') }, {
            headers: { 'Content-Type': 'application/json', apikey: config.apikey, tenant: config.tenant }
          }),
        ]);
        setSupportedClub(clubsResponse.data.clubs.find((club) => club._id === supportedClub)?.name || '');
        setTotalDebitAmount(debitsResponse.data.totalDebitAmount);
        setSortedDebitHistory(sortDebitsByDate(debitsResponse.data.debitHistory.documents));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (supportedClub) {
      fetchData();
    }
  }, [supportedClub]);

  const sortDebitsByDate = (debits) => {
    return debits.sort((a, b) => new Date(b.monthYear) - new Date(a.monthYear));
  };

  const handleToggle = useCallback(async (apiEndpoint, stateSetter, stateValue) => {
    try {
      await axios.post(apiEndpoint, { cdrid: localStorage.getItem('cdrid'), stateValue }, {
        headers: { 'Content-Type': 'application/json', apikey: config.apikey, tenant: config.tenant }
      });
      stateSetter(!stateValue);
    } catch (error) {
      console.error('Error toggling state:', error);
    }
  }, []);

  const handleRoundUpsToggle = () => handleToggle(api.setPaused, setRoundUpsOn, roundUpsOn);
  const handleWeeklyLimitToggle = () => handleToggle(api.setWeeklyLimit, setWeeklyLimitOn, weeklyLimitOn);

  const formik = useFormik({
    initialValues: { amount: 0, cdrid: localStorage.getItem('cdrid') },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoadingDonation(true);
      try {
        await axios.post(api.donateNow, { values }, {
          headers: { 'Content-Type': 'application/json', apikey: config.apikey, tenant: config.tenant }
        });
        setIsDonated(true);
        setTimeout(() => {
          setOpen(false);
          setIsDonated(false);
        }, 4000);
      } catch (error) {
        console.error('Error processing donation:', error);
      } finally {
        setIsLoadingDonation(false);
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="primary" maxWidth="xs">
        <AppBar position="static" color="appbar" elevation={0}>
          <Toolbar>
            <DrawerMenu />
            <Typography variant="body" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
      </Container>

      <Container component="secondary" maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2} borderRadius={4}>
          <Grid container justifyContent="center" mb={2}>
            <Box display="flex" justifyContent="center">
              <img src={Logo} alt="Logo" style={{ width: '60%', height: 'auto' }} />
            </Box>
          </Grid>
          <Typography variant="body" align="center" sx={{ fontWeight: 'bold' }} gutterBottom>
            Supporting:
          </Typography>
          <Typography variant="h6" align="center" gutterBottom>
            {supportedClub}
          </Typography>
          <Divider />
          <Typography variant="h5" align="center" gutterBottom>
            ${((totalDebitAmount / 100).toFixed(2))}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2} borderRadius={4}>
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold' }}>
                Round-Ups {roundUpsOn ? 'On' : 'Paused'}
              </Typography>
            </Grid>
            <Grid item xs={4} container justifyContent="flex-end">
              <FormControlLabel
                control={<Switch checked={roundUpsOn} onChange={handleRoundUpsToggle} />}
              />
            </Grid>
          </Grid>
          <Collapse in={roundUpsOn}>
            <FormControl>
              <FormLabel>Round-Up to the nearest...</FormLabel>
              <RadioGroup row value={roundUpValue} onChange={(e) => setRoundUpValue(e.target.value)}>
                <FormControlLabel value={100} control={<StyledRadio />} label="$1" />
                <FormControlLabel value={200} control={<StyledRadio />} label="$2" />
                <FormControlLabel value={500} control={<StyledRadio />} label="$5" />
              </RadioGroup>
            </FormControl>
          </Collapse>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2} borderRadius={4}>
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <Typography variant="body2" mt={1} sx={{ fontWeight: 'bold' }}>
                Weekly Limit {weeklyLimitOn ? 'On' : 'Off'}
              </Typography>
            </Grid>
            <Grid item xs={4} container justifyContent="flex-end">
              <FormControlLabel
                control={<Switch checked={weeklyLimitOn} onChange={handleWeeklyLimitToggle} />}
              />
            </Grid>
          </Grid>
          <Collapse in={weeklyLimitOn}>
            <FormControl>
              <FormLabel>Limit round-up amount per week:</FormLabel>
              <RadioGroup row value={weeklyLimitValue} onChange={(e) => setWeeklyLimitValue(e.target.value)}>
                <FormControlLabel value={750} control={<StyledRadio />} label="$7.50" />
                <FormControlLabel value={1000} control={<StyledRadio />} label="$10.00" />
                <FormControlLabel value={1250} control={<StyledRadio />} label="$12.50" />
              </RadioGroup>
            </FormControl>
          </Collapse>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center" bgcolor="white" mt={2} p={2} borderRadius={4}>
          <Button onClick={() => setOpen(true)} fullWidth variant="contained" color="primary">
            Donate Now
          </Button>

          <Modal open={open} onClose={() => setOpen(false)}>
            {!isDonated ? (
              <Box sx={style}>
                <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                  One-Off Donation
                </Typography>
                <Typography sx={{ mt: 2, mb: 2 }}>You can donate up to $250 in a one-off donation</Typography>
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    fullWidth
                    id="amount"
                    name="amount"
                    label="Amount"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    error={formik.touched.amount && Boolean(formik.errors.amount)}
                    helperText={formik.touched.amount && formik.errors.amount}
                    required
                  />
                  <Box display="flex" justifyContent="space-between">
                    <Button onClick={() => setOpen(false)} fullWidth variant="contained">
                      Cancel
                    </Button>
                    <Button type="submit" fullWidth variant="contained" disabled={isLoadingDonation}>
                      {isLoadingDonation ? 'Loading...' : 'Submit'}
                    </Button>
                  </Box>
                </form>
              </Box>
            ) : (
              <Box sx={style}>
                <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                  Thank You!
                </Typography>
                <Typography sx={{ mt: 2, mb: 2 }}>
                  Your donation request has been received and may take 2-3 days to process.
                </Typography>
              </Box>
            )}
          </Modal>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Dashboard;
