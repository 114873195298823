import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, Container, Typography, Box, Button, Grid, Link } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Logo from '../../../assets/images/logo_a.svg';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { footerLinks } from '../Shared/footerLinks';
import { theme, style, config, StyledRadio, CustomListItem } from '../../../config/settings.jsx';
import { api } from '../../../config/apis.jsx';



type Club = {
  _id: string;
  name: string;
};

const ClubSelect = () => {
  const [clubs, setClubs] = useState<Club[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClubId, setSelectedClubId] = useState<string | null>(null);
  const sourceClub = localStorage.getItem('sourceClub');
  const navigate = useNavigate();

  useEffect(() => {
    fetchClubs();
  }, []);

  const fetchClubs = async () => {
    try {
      const response = await axios.get(api.retrieveClubs,
        { headers: { 'Content-Type': 'application/json',
          apikey: config.apikey,
          tenant: config.tenant
         } }
      );
      setClubs(response.data.clubs);
    } catch (error) {
      console.error('Error fetching clubs:', error);
    }
  };

  let filteredClubs;

 if (searchTerm) {
      filteredClubs = clubs.filter(club =>
        club.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      filteredClubs = clubs;
    }

  const handleClubSelect = (clubId: string) => {
    setSelectedClubId(clubId);
    navigate('/register2', { state: { selectedClubId: clubId } });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box display="flex" flexDirection="column" alignItems="center" mt={8} p={2} >
        <Grid container justifyContent="center" mb={2}>
          <Grid item xs={12} justifyContent="center">
            <Box display="flex" justifyContent="center">
              <img src={Logo} alt="Logo" style={{ width: '70%', height: 'auto'}} />
            </Box>
          </Grid>
        </Grid>

        <Typography variant="h6" component="h2" align="center" gutterBottom>
          Choose an organisation to support 
        </Typography>
        <Box display="flex" alignItems="center" mb={2} style={{ width: '100%'}}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            placeholder="Search for a club here..."
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '16px', // Apply border radius
              },
          }}
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </Box>
        <Box style={{ maxHeight: '300px', overflowY: 'auto' }}>
          <List>
            {filteredClubs.map(club => (
              <CustomListItem 
                key={club._id}
                 onClick={() => handleClubSelect(club._id)}
                selected={selectedClubId === club._id}
              >
                <ListItemText primary={club.name} />
              </CustomListItem>
            ))}
          </List>
        </Box>

        <Grid item xs={12} justifyContent="center">
          <Box display="flex" justifyContent="center" p={1}>
            <Typography variant="body2"> Already have an account? 
              <Link component={RouterLink} to="/" variant="body2" sx={{ ml: 0.5, textDecoration: 'none' }}> Login
              </Link>
            </Typography>
          </Box>
        </Grid>

       {footerLinks}
      </Box>
    </Container>
  );
};

export default ClubSelect;

export {};
