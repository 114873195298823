import { useState, useEffect } from 'react';
import axios from 'axios';
import { api } from '../config/apis.jsx';
import { config } from '../config/settings.jsx';

const useUserData = (userId) => {
  const [userData, setUserData] = useState(null);
  const [cdrid, setCdrid] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userId) return;

    const getUserData = async () => {
      try {
        const response = await axios.post(
          api.getUser,
          { userId },
          { headers: { 'Content-Type': 'application/json', apikey: config.apikey, tenant: config.tenant } }
        );
        const userRecord = response.data.userRecord.document;
        setUserData(userRecord);
        setCdrid(userRecord._id);
      } catch (err) {
        setError('Failed to fetch user data.');
      }
    };

    getUserData();
  }, [userId]);

  return { userData, cdrid, error };
};

export default useUserData;
