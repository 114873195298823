import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Grid, Box, Link, FormControlLabel, Checkbox} from '@mui/material';
import Logo from '../../../assets/images/logo_a.svg';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebaseConfig';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { footerLinks } from '../Shared/footerLinks';
import { theme, style, config, StyledRadio, CustomListItem } from '../../../config/settings.jsx';
import { api } from '../../../config/apis';




  const validationSchema = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Enter a valid email').required('Email is required'),
    password: yup.string().min(8, 'Password should be of minimum 8 characters length').required('Password is required'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), undefined], 'Passwords must match').required('Confirm password is required'),
    mobileNumber: yup.string()
      .matches(/^\d{10}$/, 'Mobile number must be 10 digits, no spaces')
      .required('Mobile number is required'),
  });
  
  const RegisterUser = () => {
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedClubId } = location.state as { selectedClubId: string };
   
    const formik = useFormik({
      initialValues: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        mobileNumber: '',
      },
      validationSchema: validationSchema,
         onSubmit: async (values) => {
         setIsLoading(true);
        try {
          const userCredential = await createUserWithEmailAndPassword(auth, values.email, values.password);
          const {email, firstName, lastName, mobileNumber } = values;
          localStorage.setItem('userId', userCredential.user.uid)
          localStorage.setItem('clubId', selectedClubId)
         

          const response = await axios.post(api.register,
            {email: email, firstName: firstName, lastName: lastName, mobile: mobileNumber, tenantA: config.tenantA, supportingClubId: selectedClubId, userId: userCredential.user.uid},
            { headers: { 'Content-Type': 'application/json',
              apikey: config.apikey,
              tenant: config.tenant
             } }
          );
          console.log("response:", response.data.CDRID);
          localStorage.setItem('cdrid', response.data.CDRID);
          navigate('/launch-consent');
        } catch (error) {
          console.error('Error registering user:', error);
          setMessage('Failed to register. Please try again.');
        }
      },
    });
  

    return (
      <Container component="main" maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center" mt={8} p={2}>
          <Box display="flex" justifyContent="center" mb={2}>
            <img src={Logo} alt="Logo" style={{ width: '90%', height: 'auto' }} />
          </Box>
      
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              label="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
                <TextField
              fullWidth
              id="mobileNumber"
              name="mobileNumber"
              label="Mobile Number"
              placeholder="0#########"
              value={formik.values.mobileNumber}
              onChange={formik.handleChange}
              error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
              helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
            <TextField
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              required
              variant="outlined"
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '16px',  height: '45px', mt: '3px', mb: '3px'}, }}            />
          <Grid item xs={12} justifyContent="left">
      <Box display="flex" justifyContent="left">
      <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="I agree to the terms and conditions"
            required
          />
      </Box></Grid>
                <Box display="flex" justifyContent="center" sx={{mt: '10px', color: 'red'}}> {message}</Box> 
            <ThemeProvider theme={theme}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2, borderRadius: 3 }}
                disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Register'}

              </Button>
            </ThemeProvider>

            <Grid item xs={12} justifyContent="center">
          <Box display="flex" justifyContent="center" p={1}>
          <Typography variant="body2"> Made a mistake? <Link component={RouterLink} to="/clubs" variant="body2" sx={{ ml: 0.5, textDecoration: 'none' }}> Go back
            </Link>
            </Typography></Box>
            </Grid>
            <Grid item xs={12} justifyContent="center">
          <Box display="flex" justifyContent="center" p={1}>
          <Typography variant="body2"> Already have an account? <Link component={RouterLink} to="/" variant="body2" sx={{ ml: 0.5, textDecoration: 'none' }}> Login
            </Link>
            </Typography></Box>
            </Grid>
                    
          {footerLinks}
          </form>
        </Box>
      </Container>
    );
  };
  
  export default RegisterUser;

export {};