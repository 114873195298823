import { createTheme, styled } from '@mui/material/styles';
import { Radio, ListItem } from '@mui/material';


export const config = {
    apikey: 'F3tDuot6*otiwCp$aRW9ahcPKHkMUwxUUE&tW4#vg84%YV7Ud56s@dVhCzoZrkBpRjwF!96Pp7Z*PJZFZuSC5ZesHScYNtQxij%A^Ta$SNA@QFd*!HZ2k4h@JH3cZYtf',
    tenant: 'ROUNDA_PROD',
    tenantA: 'ROUNDA-STAGE',
    
  };

export const theme = createTheme({
    palette: {
      primary: {
        main: '#000000',
      },
      background: {
        default: '#47b3b5', // Set your desired background colour here
      },
      appbar: {
        default: '#ffffff', // Set your desired background colour here
      },
      switchColor: {
        main: '#47b3b5',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#ffffff', // Ensure the body has the same background colour
          },
          secondary: {
            backgroundColor: '#47b3b5', // Ensure the body has the same background colour
          },
          switch: {
            main: '#47b3b5',
          }
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: '#47b3b5',
            },
            '&.Mui-completed': {
              color: '#47b3b5',
            },
          },
        },
      },
  }});


 export const StyledRadio = styled(Radio)(({ theme }) => ({
    '&.Mui-checked': {
      color: '#47b3b5', // Custom color for checked state
    },
    '& .MuiSvgIcon-root': {
      fontSize: 24, // Adjust the size to match checkbox size
    },
  }));


  export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 6,
    p: 4,
  };

  export const CustomListItem = styled(ListItem)(({ theme }) => ({
    '&.Mui-selected': {
      backgroundColor: '#47b3b5',
      '&:hover': {
        backgroundColor: '#47b3b5',
      },
    },
  }));
  